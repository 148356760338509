import request from '@/utils/request'

// 根据令牌登录
export function loginByToken (data) {
  return request.post('/usr/loginByToken', data)
}

// 获取登录用户信息
export function fetchLoginUserInfo () {
  return request.get('/usr/loginInfo', {
    autoLogin: false
  })
}

// 修改ApiKey
export function updateApiKey (data) {
  return request.post('/usr/update/apikey', data)
}
