import Vue from 'vue'
import Vuex from 'vuex'
import { fetchLoginUserInfo } from '@/api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 登录用户信息
    userInfo: null
  },
  mutations: {
    // 设置用户信息
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    // 获取登录用户信息
    async fetchLoginUserInfo ({ commit }) {
      await fetchLoginUserInfo()
        .then(data => {
          commit('setUserInfo', data)
        })
        .catch(() => {
        })
    }
  },
  modules: {
  }
})
