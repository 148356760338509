import { Message } from 'element-ui'

export default {
  ...Message,
  /**
   * 接口调用成功
   * @param message 提示消息
   */
  apiSuccess (message) {
    Message.success(message)
  },
  /**
   * 接口调用失败
   * @param err 错误对象
   */
  apiFailed (err) {
    if (!err.message.startsWith('#ignore#')) {
      Message.error(err.message)
    }
  }
}
