import consts from './consts'
import message from './message'
export default {
  install (Vue) {
    // 常量
    Vue.prototype.$consts = consts
    // 消息提示
    Vue.prototype.$tip = message
  }
}
