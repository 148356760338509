<script>
import { mapState } from 'vuex'
export default {
  name: 'BaseUser',
  data () {
    return {
      config: {
        appId: process.env.VUE_APP_ID,
        adjustRdDomain: process.env.VUE_APP_ADJUSTRD_DOMAIN,
        loginURI: process.env.VUE_APP_LOGIN_URI,
        innerLoginURI: '/inner' + process.env.VUE_APP_LOGIN_URI,
        loginRedirectURL: process.env.VUE_APP_LOGIN_REDIRECT_URL,
        loginDigest: process.env.VUE_APP_LOGIN_DIGEST,
        loginTheme: process.env.VUE_APP_LOGIN_THEME
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 登录
    toLogin () {
      const payload = window.location.href
      window.location.href = `${this.config.adjustRdDomain}${this.config.loginURI}?appid=${this.config.appId}&redirect_uri=${this.config.loginRedirectURL}&digest=${this.config.loginDigest}&payload=${payload}&theme=${this.config.loginTheme}`
    }
  }
}
</script>
