import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Home
  },
  {
    path: '/usr/autologin',
    name: 'AutoLogin',
    component: () => import('@/views/usr/autologin')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  // 未登录
  if (router.app.$options.store.state.userInfo == null) {
    await router.app.$options.store.dispatch('fetchLoginUserInfo')
  }
  // 未登录 & 需要登录 => 跳转至首页
  if (router.app.$options.store.state.userInfo == null) {
    // 必须登录的页面，跳转至首页
    if (to.matched[0].meta.needLogin === true) {
      next({ name: 'Index' })
      return
    }
  }
  next()
})

export default router
